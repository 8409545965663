/* Main Styles */
/* Main Offer Container */
[hidden] {
    display: none !important;
}

.access-offers-wrapper .access-methods-list {
    display: grid;
    grid-gap: 0px 30px;
    align-items: stretch;
    margin: 10px 10% 30px;
    padding-bottom: 20px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.access-offers-wrapper.access-offers-full .access-methods-list {
    margin-left:0;
    margin-right: 0;
}

.access-offers-wrapper.access-offers-modal .access-methods-list {
    display: flex;
    grid-gap: 30px;
    flex-flow: row;
    align-items: stretch;
    margin: 10px 20px 30px
}

.access-offers-wrapper .access-methods-list .subscription-service {
    display: grid;
    grid-template-areas: "header" "image" "body" "footer" "description";
    width: 100%;
    background: #fff;
    padding: 0px;
    border:10px solid #efefef;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    position: relative
}

#access-methods.access-methods-list.single-offer, 
.access-offers-wrapper .access-methods-list.single-offer { /* single offer mode */
    grid-template-columns: repeat(auto-fit, minmax(200px, 480px));
    justify-content: center;
    align-items: center;
    text-align: center;
}

.access-offers-wrapper .access-methods-list.single-offer .subscription-service {
    display: inline-block;
    max-width: 480px;
}

/* Featured Widget */
.access-offers-wrapper .access-methods-list .subscription-service .method-featured,
#access-offers-modal .method-featured,
.access-offers-wrapper .access-methods-list .purchase-form-wrapper .featured {
    position: absolute;
    top: -36px;
    margin-left: 20px;
}

.access-offers-wrapper .access-methods-list .subscription-service .method-featured,
#access-offers-modal .method-featured,
.access-offers-wrapper .purchase-form-wrapper .featured {
    width: calc(100% - 40px);
    height:40px;
    background: #2c5c2c;
    color: #fff;
    line-height: 40px;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: .1em
}

.access-offers-wrapper .purchase-form-wrapper .featured {
    margin-top: -50px;
}

#access-offers-modal .method-featured { top: -16px }
.access-offers-wrapper .featured-message { text-indent: -40px }

.access-offers-wrapper .access-methods-list .subscription-service .method-featured:empty,
.access-offers-wrapper .purchase-form-wrapper .featured:empty { display: none }

.access-offers-wrapper .access-methods-list .subscription-service .method-featured .featured-icon:before,
.access-offers-wrapper .purchase-form-wrapper .featured:before {
    content: '\2605';
    width: 40px;
    height:40px;
    background: #197a30;
    color: #fff;
    line-height: 38px;
    font-size: 21px;
    font-weight: 600;
    display: block;
    float: left
}

.access-offers-wrapper .access-methods-list .subscription-service .method-featured .featured-message,
.access-offers-wrapper .purchase-form-wrapper .featured .featured-message {
    display: block;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.05em;
}

/* Loading */
.access-offers-wrapper .promo-loading {
    font-size: 30px;
}

/* Header Styles */
.access-offers-wrapper .access-methods-list .subscription-service .method-header {
    grid-area: header;
    padding: 40px 0px 10px;
    font-weight: bold;
    font-size: 18px
}

/* Image Styles */
.access-offers-wrapper .access-methods-list .subscription-service .method-image {
    grid-area: image;
    padding-bottom: 0px;
    justify-self: center;
    align-self: center
}
.access-offers-wrapper .access-methods-list .subscription-service .method-image img {
    max-height: 120px;
    object-fit: contain;
    max-width: none;
    margin:0 auto
}

/* Offer Text Styles */
.access-offers-wrapper .subscription-service .offer-details {
    grid-area: body;
    text-align: center;
    min-height: 138px
}

.access-offers-wrapper .subscription-service .offer-rate,
.asset #asset-content .access-offers-wrapper .subscription-service p.offer-rate {
    color: #003471;
    font-weight: 700;
    font-size: 28px
}

.access-offers-wrapper .subscription-service .offer-limits,
.asset #asset-content .access-offers-wrapper .subscription-service p.offer-limits {
    color: #595959;
    font-size: 14px;
    font-weight: bold
}

.access-offers-wrapper .subscription-service .offer-savings,
.asset #asset-content .access-offers-wrapper .subscription-service p.offer-savings {
    color: #db7800;
    font-size: 20px;
    font-weight: bold
}

.access-offers-wrapper .subscription-service .offer-cycle,
.asset #asset-content .access-offers-wrapper .subscription-service p.offer-cycle {
    color:#000;
    font-size: 12px
}

.access-offers-wrapper .subscription-service .offer-cancel { font-size: 11px }

#auth-links-wrapper {
    display: flex;
    gap: 15px;
}

#auth-links-wrapper .auth-link {
    width: 100%;
}

#access-offers-modal .modal-body .offer-group-secure-signup .access-login .login-toggle { 
    display: block;
    padding-top: .5em;
}

@media (min-width: 992px) {
    .access-offers-wrapper .access-methods-list.single-offer .subscription-service {
        max-width: none;
    }

    #auth-links-wrapper .auth-link {
        width: auto;
    }
    
    #access-offers-modal .modal-body .offer-group-secure-signup .access-login .login-toggle { 
        display: inline;
        padding-top: 0;
    }
}

/* Button Styles */
.access-offers-wrapper .btn {
    border:none;
    background-color: #ba3838;
    box-shadow: 0px 5px 0px 0px #9f1f1f;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px 5px;
    text-decoration: none;
    margin: 0px auto 20px;
    position: relative;
    display: inline-block;
    font-weight: bold;
    line-height: normal;
    white-space: normal
}

.access-offers-wrapper .btn.btn-lg {
    font-size: 16px;
}

.access-offers-wrapper .btn:hover {
    background-color: #c34a3d;
}

.access-offers-wrapper .btn.btn-instant-login { 
    background-color: #305b80;
    border-color: #357cbd;
    box-shadow: 0 5px 0 0 #357cbd
}

.access-offers-wrapper .btn.btn-instant-login:hover{ background-color: #357cbd }
.access-offers-wrapper .forgot-wrapper { padding-top: 10px }

/* Wallet Buttons */
.access-offers-wrapper .btn.wallet-btn {
    background-color: #ffffff;
    box-shadow: 0px 5px 0 0 #cfcccc;
    color: #222222;
    text-align: left;
    transition: color .25s, background-color .25s;
}
.access-offers-wrapper .btn.wallet-btn::before {
   content: '';
   display: inline-block;
   width: 12px;
   height: 12px;
   border-radius: 6px;
   border: solid 2px #cfcccc;
   position: absolute;
   left: 10px;
   top: calc(50% - 6px);
}

.access-offers-wrapper .btn.wallet-btn.tn-pay-method-selected {
    background: #447755;
    color: #efeeee;
    outline: 1px solid #003300;
    box-shadow: 0 5px 0 0 #006600;
}
.access-offers-wrapper .btn.wallet-btn.tn-pay-method-selected .tn-cc-exp, 
.access-offers-wrapper .btn.wallet-btn.tn-pay-method-selected .tn-cc-label {
    color: #eeeeee;
}
.access-offers-wrapper .btn.wallet-btn.tn-pay-method-selected::before {
   border: solid 2px #ffffff;
   background: #006600;
}

/* Paypal Button */
.access-offers-wrapper .btn.wallet-btn.tn-pay-method-selected.tn-pay-method-paypal {
    background: rgb(0, 156, 222); 
    box-shadow: 0 5px 0 0 rgb(0, 80, 150);
    outline: 1px solid rgb(0, 80, 150);
    color: #ffffff;
}
.access-offers-wrapper .btn.wallet-btn.tn-pay-method-selected.tn-pay-method-paypal::before {
    background: rgb(0, 80, 150);
}

.access-offers-wrapper .btn.wallet-btn .tn-cc-text {
    padding-left: 5px;
}
.access-offers-wrapper .btn.wallet-btn .tn-cc-lg {
   padding: 8px;
}
.access-offers-wrapper .btn.wallet-btn .tn-cc-lg svg { 
    height: 32px;
    width: 50px;
    margin-right: 8px;
}
.access-offers-wrapper .btn.wallet-btn .tn-cc-heading {
    display: inline-block;
}
.access-offers-wrapper .btn.wallet-btn .tn-cc-heading .tn-cc-ending-in{
    display: block;
}
.access-offers-wrapper .btn.wallet-btn .tn-cc-heading .tn-cc-label {
    font-size: 14px;
}

.access-offers-wrapper .btn.tn-pay-method-new-card {
    text-align: center;
 /*    background-color: #444444;
    box-shadow: 0px 5px 0 0 #000000; */
}
.access-offers-wrapper .btn.tn-pay-method-new-card .tn-pay-cards {
    position: initial;
    padding: 5px 0 0 0;
    top:0;
    margin:0;
}

/* Footer Styles */
.access-offers-wrapper .access-methods-list .subscription-service .method-footer {
    grid-area: footer;
    justify-self: center;
    text-align: center
}

/* Description - in offer display */
.access-offers-wrapper .subscription-service .method-description {
    grid-area: description;
    margin: 0 20px 20px 20px;
    padding: 0 10px;
    text-align: center;
    grid-area: description;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 100%)
}
.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .purchase-featured .subscription-service .method-description { margin: 0 }

.access-offers-wrapper .subscription-service .method-description h4 {
    border-bottom: solid 1px #ffffff ;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
    margin: 0
}

.access-offers-wrapper .subscription-service .method-description .text-wrapper { 
    text-align: left;
    font-size: 13px;
    line-height: 16px;
    padding: 10px;
    margin-top: 8px
}

.access-offers-wrapper .tncms-recaptcha-wrapper {
    display: inline-block;
}


/* Offer Modal Styles */
.access-offers-modal .modal-content { background: #E3E9F0 }

.access-offers-modal .modal-header h3 {
    font-size: 18px;
    border-bottom: none;
    margin: 0
}
.access-offers-modal .modal-header h3:empty { display: none }

.access-modal .modal-header .tab-back {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    box-shadow: none;
    float: left
}
.access-modal .modal-header .tab-back:focus { outline: none }
.access-modal .modal-header .tab-back span { display: none }
.access-modal .modal-header .tab-back:after {
    content: '\27F5';
    font-size: 21px;
    line-height: 5px;
    color: #999
}
.access-modal .modal-header .tab-back:focus:after { color: #000 }
.access-modal .modal-header h3 { font-weight: bold }

.access-modal .modal-header h3,
.form-group span.has-error,
.form-group-flex span.has-error {
    font-weight: bold;
}

/* Flexible form group */
.form-group-flex {
    align-items: flex-end;
    column-gap: 30px;
    row-gap: 15px;
}

.form-group-flex.tax-result {
    display: flex;
    text-align: center;
}

.form-group-flex > * {
    flex: 1;
    margin: 0 -15px 15px;
}

@media (min-width: 768px) {
    .form-group-flex {
        display: flex;
        margin: 0 -15px 15px;
    }

    .form-group-flex > * {
        flex-grow: 1;
        margin: 0;
    }

    .form-group-flex.tax-result {
        text-align: initial;
    }

    .form-group-flex #tax-subtotal-container {
        text-align: right;
    }
}

.tnt-tax-detail .form-group-flex {
    align-items: flex-start;
}

/* Modal Purchase Styles */
.access-offers-modal .subscribe-form-purchase-rate .alert-info {
    background: #fff;
    border: 0;
    box-shadow: none;
    border-radius: 3px;
    position: relative;
    padding-bottom: 32px;
    cursor: pointer
}
.access-offers-modal .subscribe-form-purchase-rate .alert-info:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width:7px;
    background: #69C93F;
    border-radius:3px;
    z-index: 2
}
.access-offers-modal .subscribe-form-purchase-rate .alert-info:after {
    content:'\2714';
    position: absolute;
    left: 20px;
    top: 16px;
    height: 30px;
    width:30px;
    background: #69C93F;
    border-radius:50%;
    text-align: center;
    line-height: 30px;
    color: #fff
}

.access-offers-wrapper .subscribe-form-purchase-rate .alert-info .rate-name {
    margin-left: 50px;
    font-weight: bold;
    color:#073763;
    margin-bottom: 5px
}
.access-offers-wrapper .subscribe-form-purchase-rate .alert-info .rate-price,
.access-offers-wrapper .subscribe-form-purchase-rate .alert-info .rate-tax {
    margin-left: 50px;
    margin-bottom: 5px;
    color: #000
}

.access-offers-wrapper .tax-result {
    font-weight: bold;
    font-size: 18px;
    color: #010101;
}

.access-offers-wrapper .tax-total .tax-assessed {
    margin-right: 15px;
}

@media (max-width: 991px) {
    .access-offers-wrapper .form-group .row div:not(:last-child) input {
        margin-bottom: 15px;
    }
}

.access-offers-wrapper .subscribe-form-purchase-rate .alert-info .rate-info-expander {
    width: 100%;
    padding: 4px 0 0;
    text-align: center;
    position: absolute;
    bottom: 0px;
    left:0px;
    background: #efefef;
    border-radius: 0 0 3px 3px;
    text-transform: capitalize;
    font-weight: bold;
    color: #666
}

.access-offers-wrapper .subscribe-form-purchase-rate .alert-info .service-details {
    margin: 5px 50px 20px;
}

.access-offers-wrapper .subscribe-form-purchase-rate .alert-info .service-details .service-link {
    font-weight: bold;
    text-decoration: underline
}

.access-offers-wrapper .form-horizontal legend {
    margin-left: -15px;
    font-size: 15px;
    margin-bottom: 10px;
    color:#666
}

.access-offers-wrapper fieldset { margin-top: 30px }
.access-offers-wrapper #user-register-form fieldset { margin-top: 0px }
.access-offers-wrapper fieldset:first-of-type { margin-top: 0 }

.access-offers-wrapper .form-horizontal label {
    color:#666;
    font-size: 13px;
    margin-right: 3px;
    font-weight: normal
}
.access-offers-wrapper .form-horizontal .form-control,
.access-offers-wrapper .user-login-form .form-control {
    border-radius: 5px;
    border-width: 0px;
}

.access-offers-wrapper .form-horizontal .form-group.has-error .form-control {
    border-width: 1px;
}

.access-offers-wrapper .form-horizontal  .control-label {
    padding-top: 0;
    margin-bottom: 5px
}

/* Terms of Service */
.access-offers-wrapper .tn-tos-group {
    padding: 15px 30px
}
.access-offers-wrapper .tn-tos-agree { 
    display: inline-block;
    padding-left: 10px;
    position: relative;
    top: -2px;
    line-height: 18px;
    color: #333;
    font-weight: bold;
}
.access-offers-wrapper .service-renews {
    display: inline-block;
    padding-left: 25px;
}

/* Secure Notices */
.access-offers-wrapper .secure-notice {
    font-weight:normal;
    color: #4a4a4a;
    font-size: 13px;
}

/* Hide Cancel Within Offer in paywalls */
.access-offers-modal .subscription-service .offer-cancel,
.access-offers-in-page:not(.access-offers-block) .subscription-service .offer-cancel {
    display: none;
}

.access-offers-modal .purchase-success-checkmark {
    font-size:20em;
    color:#008800
}

#access-offers-modal #access-modal-login-form #user-password.form-control,
#offer-promo-search input[name=promoCode],
#service-promo-fetch input[name=promoCode] {
    border-radius: 5px 0 0 5px;
}

#access-offers-modal #access-modal-login-form .input-group-addon {
    border-radius: 0 5px 5px 0;
    border: 0;
    background-color: rgb(232, 240, 254);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

/* Federated authentication */
#access-offers-modal .user-login-or-container {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #999; 
    line-height: 0.1em;
    margin: 20px 0px
}

#access-offers-modal .user-login-or-container .user-login-or {
    background: #e3e9f0;
    padding: 0 10px;
    font-weight: bold
}
#access-offers-modal.access-methods-modal .user-login-or-container .user-login-or { background: #fff }

#access-offers-modal .user-login-fed-auth .btn {
    box-shadow: 0px 2px 0px 0px rgb(0 0 0 / 10%);
    border-radius: 5px;
    margin-bottom: 10px;
    background: rgb(239, 239, 239);
    color: inherit
}

#access-offers-modal .user-login-fed-auth .btn:hover { background: rgb(0 0 0 / 5%) }

#access-offers-modal .user-login-fed-auth .tnt-facebook-square { color: #3a59a5 }

#access-offers-modal .user-login-fed-auth .tnt-google-plus-square { color: #b83a3b }

#offers-modal-user-links a { margin: 0 10px 20px 10px }

#login-success-notification { 
    position: fixed;
    top: 5em;
    left: auto;
    right: 0;
    cursor: pointer;
    z-index: 999
}

#user-modal-signup-success-check { 
    font-size:10em;
    color:#008800
}

/* Purchase Styles */
.access-offers-wrapper .form-horizontal input[type="text"] {
    color: #555;
    font-size: 14px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif
}


/* Inline Purchase Styles */
.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 { position: relative }
.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .purchase-stage-2-backdrop { background: #fff }

.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .offer-details { text-align: left } 

.access-offers-wrapper .form-content.purchase-featured .rate-heading {
    margin-top: -45px;
}

@media (min-width: 992px) {
    .access-offers-wrapper .form-content.purchase-featured .rate-heading {
        margin-top: -55px;
    }
}

.access-offers-wrapper .purchase-stage-2 .subscription-service .rate-name,
.access-offers-wrapper .form-content .rate-heading .rate-name {
    grid-area: header;
    padding:20px 0px 10px;
    font-weight: bold;
    font-size: 18px;
    text-align: center
}
.access-offers-wrapper .purchase-stage-2 .subscription-service .rate-name { text-align: left }

.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .rate-price, 
.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .offer-rate {
    color: #003471;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 3px
}

.access-offers-wrapper .subscribe-form-purchase-rate .rate-info {
    background: #fff;
    padding: 20px;
    margin: 0 0 20px
}

.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .service-link.btn.btn-default {
    background-color: #305b80;
    border-color: #357cbd;
    box-shadow: 0 5px 0 0 #357cbd
}

.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 header {
    margin-top: 20px;
    padding: 20px 40px;
    text-align: left
}

.access-offers-wrapper header h2:empty,
.access-offers-wrapper header p:empty { display: none }

.access-offers-block.access-offers-wrapper.access-offers-in-page {
    margin-left: -15px;
    margin-right: -15px;
}

.access-offers-block.access-offers-wrapper.access-offers-in-page .service-promo-open .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .form-content {
    padding: 15px;
    margin: 20px;
    background: #fff;
    border: 10px solid #efefef;
    box-shadow: 2px 2px rgba(0,0,0,.1);
    position: relative;
    top: -40px
}

.access-offers-block .subscribe-form-purchase-rate .service-link {
    display: none;
}

.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .form-content .form-horizontal .form-control,
.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .renewal-form .form-horizontal .form-control{
    border-radius: 5px;
    border:solid 1px #666
}

.access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .form-content .featured {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 80px)
}

@media (min-width: 992px) {
    .access-offers-wrapper.external-paywall .access-methods-list.one-offer {
        margin: 0 auto;
        max-width: 600px;
    }

    .access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .form-content {
        top: -100px;
    }
    
    .access-offers-block.access-offers-wrapper.access-offers-in-page {
        margin-left: 15px;
        margin-right: 15px;
    }
    
    .access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .form-content {
        padding: 23px;
    }
    
    .access-offers-block .subscribe-form-purchase-rate .service-link {
        display: inline-block;
    }
    
    .access-offers-wrapper .inline-purchase-stage.purchase-stage-2 .form-content .featured {
        width: calc(100% - 120px)
    }
}

.access-offers-wrapper .inline-purchase-stage.purchase-stage-final .purchase-success-notifications {
    padding: 20px;
    margin-bottom: 20px;
    font-size: 18px
}

.access-offers-wrapper .inline-purchase-stage.purchase-stage-final .purchase-success-checkmark { font-size:10em }

.access-offers-wrapper .inline-purchase-stage.purchase-stage-final .modal-receipt-output {
    padding: 20px;
    margin: 60px 20px 20px;
    background: #fff;
    border: 10px solid #efefef;
    box-shadow: 2px 2px rgba(0,0,0,.1);
    position: relative;
    top:-20px;
    color: #000
}
.access-offers-wrapper .inline-purchase-stage.purchase-stage-final .modal-receipt-output .service-name { margin-bottom: 20px }

/* Rate Selection */
#renewalFormPurchateRates label {
    margin-bottom: 5px;
}
#renewalFormPurchateRates label.active {
   outline: solid 5px #e5e5e5;
}

@media screen and (min-width: 1024px) {
    #access-offers-modal .modal-dialog.offer-tab {
        max-width: 1000px;
        width: 100%
    }
    #access-offers-modal.modal-small .modal-dialog.offer-tab { max-width: 800px }
}


@media ( max-width: 991px ) {
    .access-offers-wrapper { grid-gap: 10px }

    .access-offers-wrapper .access-methods-list,
    .access-offers-wrapper.access-offers-modal .access-methods-list {
        display: flex;
        grid-gap: 30px;
        flex-flow: column;
        align-items: stretch;
        font-family: Roboto, sans-serif;
        margin: 30px 10px
    }

    .access-offers-wrapper .access-methods-list .subscription-service,
    #modal-offers-tab #access-methods .subscription-service {
        grid-template-areas: "header header" "body footer" "description description";
        grid-gap: 10px;
        grid-template-columns: auto 150px;
        padding:10px 15px
    }

    .access-offers-modal .access-methods-list .subscription-service.panel {
        margin-top: 0;
        margin-bottom: 5px
    }

    .access-offers-wrapper .access-methods-list .subscription-service .method-image { display: none }
    .access-offers-wrapper .access-methods-list .subscription-service .method-featured,
    #access-offers-modal .method-featured {
        width: auto;
        margin-left: auto;
        top: -28px;
        right: 0;
        height: 25px;
        line-height: 25px;
        font-size: 12px;
        letter-spacing: normal
    }
    #access-offers-modal .method-featured { top: -12px }

    .access-offers-wrapper .access-methods-list .subscription-service .method-featured .featured-message {
        padding-left: 40px;
        padding-right: 20px;
        margin-left: 40px
    }

    .access-offers-wrapper .access-methods-list .subscription-service .method-featured .featured-icon::before {
        height: 25px;
        width: 25px;
        line-height: 25px;
        font-size: 16px
    }

    .access-offers-wrapper .access-methods-list .subscription-service .method-header {
        text-align: left;
        padding-top: 10px;
        padding-bottom: 5px
    }

    .access-offers-wrapper .access-methods-list .subscription-service .method-footer {
        align-self: center;
        justify-self: center
    }
    .access-offers-wrapper .access-methods-list .subscription-service .offer-details {
        text-align: left;
        min-height: initial
    }
    .access-offers-wrapper .access-methods-list .subscription-service .offer-rate {
        color: #003471;
        font-weight: 700;
        font-size: 20px;
        line-height: normal
    }
    .access-offers-wrapper .access-methods-list .subscription-service .offer-limits {
        color: #999;
        font-weight: bold
    }
    .access-offers-wrapper .access-methods-list .subscription-service .offer-details p { margin-bottom: 0 }
    .access-offers-wrapper .access-methods-list .subscription-service .btn {
        padding: 10px 10px 5px;
        margin-left: 5px
    }
    
    .access-offers-wrapper .access-methods-list .subscription-service .method-description { margin: 0 5px 5px 5px }
    .access-offers-wrapper .access-methods-list .subscription-service .method-description.hide-mobile .text-wrapper { display: none }
    
    #login-success-notification { top: 0 }
    
    .access-offers-wrapper .tn-tos-group { padding: 15px 0px }
}

@media ( max-width: 360px ) {
    .access-offers-wrapper .tn-tos-group { padding: 15px 0px }
    .access-offers-wrapper input[name=tos_confirm] { position: relative; top: -18px }
    .access-offers-wrapper .tn-tos-group .tos-link { display: block }
}

/* Nag Mode Styles */
#asset-content .subscriber-premium .subscriber-preview.prompt-additional-content {
    -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}

/* Spreedly Credit Card Styles */
.tn-pay-cards { 
    position: relative;
    top: -13px;
    margin: -15px 0;
    padding: 0 8px 0 0;
    font-size: 18px;
}

.tn-pay-cards span {
    display: inline-block;
    height: 20px;
}
.tn-pay-cards span.allow { display: none }

.tn-pay-cards.card-identified span { display:none }
.tn-pay-cards.card-identified span.card-active { display: inline-block }

/* In Page Paywall Display */
.asset .asset-body .access-offers-wrapper .access-methods-list { margin: 10px 0 0 }
.asset .asset-body .access-offers-wrapper .access-methods-list .promotion-service.single-offer { 
    margin: 15px 10%; 
    max-width: 80%; 
}
.asset .asset-body .access-offers-wrapper p { margin: 0 0 10px }

/* Gift offers */
#gift-redeem .btn {
    width: 100%;
}

@media (min-width: 992px) {
    #gift-redeem .btn {
        width: auto;
    }
}

/* Promo Modal Display */
#service-promo-open button.btn-primary,
#service-promo-fetch button.btn-primary { box-shadow: 0px 3px 0px 0px #9f1f1f; }

#service-promo-output {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

@media screen and (max-width: 768px){
    #service-promo-output {
        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    }

    #service-promo-output a.btn-lg{
        font-size: 16px;
    }
}

/** Layout of access method cards */ 
#service-promo-output .subscription-service {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "header header"
        "body body"
        "footer footer";
}

#user-modal-tab-promo #service-promo-output .subscription-service {
    grid-template-areas:
        "header header"
        "tagline tagline"
        "image image"
        "body body"
        "footer footer"
}

#service-promo-output .method-header {
    grid-area: header;
    align-self: start;
    text-align: center;
}

#service-promo-output .method-description {
    align-self: stretch;
    grid-area: body;
    padding: 10px;
}

#user-modal-tab-promo #service-promo-output .subscription-service .method-description {
    grid-area: none;
}

#user-modal-tab-promo #service-promo-output .subscription-service .offer-details {
    grid-area: body;
    max-width: 100%;
    overflow: hidden;
}


#access-methods .method-description ul {
    padding-left: 15px;
}

#access-methods .method-footer {
    grid-area: footer;
    align-self: end;
}

#access-methods .method-footer .btn {
    width: 100%;
}

/* 
 * TOAST THEME
 */

/* Toast Modal is always full width with no padding */
#access-offers-modal.access-offers-modal.access-display-toast .modal {
    padding-left: 0px!important;
    padding-right: 0px!important;
    padding-bottom: 0px!important;
    width: 100%!important;
}

/* toast pops up from the bottom */
.access-offers-wrapper.access-display-toast .modal.fade .modal-dialog {
    transform: translate(0,25%);
}
.access-offers-wrapper.access-display-toast .modal.fade.in .modal-dialog {
    transform: translate(0,0);
}

.access-offers-wrapper.access-display-toast .access-methods-list .subscription-service {
    box-shadow: 0 1px 4px rgba(0,0,0,.03);
    border:1px solid rgba(0,0,0,.09);
    padding: 0px 1rem;
}

.access-offers-wrapper.access-display-toast .access-methods-list .subscription-service {
    transform: scale(0.9);
}

.access-offers-wrapper.access-display-toast .access-methods-list .subscription-service:has([class^="featured-"]) {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
}

.access-offers-wrapper.access-display-toast .access-methods-list .subscription-service:has([class^="featured-"]):hover {
    transform: scale(1.0625);
    box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 0.2);
    z-index: 1;
}

.access-offers-wrapper .access-methods-list .subscription-service .method-featured,
#access-offers-modal.access-display-toast .method-featured,
.access-offers-wrapper.access-display-toast .purchase-form-wrapper .featured,
.access-offers-wrapper.access-display-toast .access-methods-list .subscription-service .method-featured .featured-icon:before,
.access-offers-wrapper.access-display-toast .purchase-form-wrapper .featured:before {
    background: #198754;
}

.access-offers-wrapper.access-display-toast .subscription-service .offer-rate,
.asset #asset-content .access-offers-wrapper.access-display-toast .subscription-service p.offer-rate {
    color: unset; 
}

.access-offers-wrapper.access-display-toast .btn {
    box-shadow: unset;
    padding-bottom: 10px;
}

.access-offers-wrapper.access-display-toast .method-link.btn,
.access-offers-wrapper.access-display-toast .btn-local-account.btn,
.access-offers-wrapper.access-display-toast .tn-pay-button.btn,
.access-offers-wrapper.access-display-toast .service-promo-open .btn,
.access-offers-wrapper.access-display-toast #service-promo-fetch.service-promo-open .btn,
.access-offers-wrapper.access-display-toast .login-toggle.btn,
.access-offers-wrapper.access-display-toast .inline-purchase-stage.purchase-stage-2 .service-link.btn.btn-default {
    box-shadow: 0px 5px 0px 0px #dc3545;
    padding: 10px 65px 5px;
    background-color: #dc3545;
}
.access-offers-wrapper.access-display-toast .service-promo-open .btn {
    padding: 10px 65px 3px;
}

.access-offers-wrapper.access-display-toast .method-link.btn:hover,
.access-offers-wrapper.access-display-toast .btn-local-account.btn:hover,
.access-offers-wrapper.access-display-toast .tn-pay-button.btn:hover,
.access-offers-wrapper.access-display-toast .service-promo-open .btn:hover,
.access-offers-wrapper.access-display-toast .login-toggle.btn:hover {
    background-color: #b82533;
    box-shadow: 0 5px 0 0 #b82533;
}

.access-offers-wrapper.access-display-toast .wallet-btn.btn,
.access-offers-wrapper.access-display-toast .wallet-btn.btn.tn-pay-method-paypal{
    border: solid 1px #999999;
    box-shadow: unset;
}

.access-offers-wrapper.access-display-toast .subscription-service .method-description {
    background: unset; 
    box-shadow: unset;
}

.access-offers-wrapper.access-display-toast .subscription-service .method-description h4 {
    border-bottom: inherit; 
    font-size: inherit; 
    font-weight: inherit; 
    padding: inherit; 
    margin: inherit; 
    display: none;
}

.access-offers-wrapper.access-display-toast .subscription-service .method-description .text-wrapper {
    padding: 0 10px 10px 10px;
    margin-top: 0;
}


@media ( max-width: 991px ) {
    #access-offers-modal.access-offers-modal.access-display-toast .modal {
        top: 0px;
    }

    .access-offers-wrapper.access-display-toast .access-methods-list {
      grid-gap: inherit;
      margin: 30px 10%;
    }

    #access-offers-modal.access-offers-wrapper.access-display-toast .access-methods-list .subscription-service,
    #access-offers-in-page-container .access-offers-in-page.access-display-toast .access-methods-list .subscription-service,
    .access-offers-wrapper.access-display-toast .access-methods-list .subscription-service,
    #modal-offers-tab #access-methods .subscription-service {
      grid-template-areas: "header header"
      "tagline tagline"
      "image image"
      "body body"
      "footer footer"
      "description description";
    }

    #access-offers-modal.access-display-toast #access-methods .subscription-service,
    .access-offers-wrapper.access-display-toast .access-methods-list .subscription-service {
      display:grid;
      grid-template-columns: repeat(1,1fr);
      grid-template-rows: auto 1fr;
      grid-gap:unset;
    }

    #access-offers-modal.access-offers-wrapper.access-display-toast .access-methods-list .subscription-service .offer-rate,
    #access-offers-in-page-container .access-offers-in-page.access-display-toast .access-methods-list .subscription-service .offer-rate,
    .access-offers-wrapper.access-display-toast .access-methods-list .subscription-service .offer-rate {
      color:#000;
      font-size: 28px;
      line-height: unset;
    }
    
    .access-offers-wrapper.access-display-toast .access-methods-list .subscription-service .method-header,
    .access-offers-wrapper.access-display-toast .access-methods-list .subscription-service .offer-details {
        text-align: center;
        margin-bottom:10px;
    }
    
    .access-offers-wrapper.access-display-toast .access-methods-list .subscription-service .method-header {
        padding: 30px 0 0;
    }

    #access-offers-modal.access-offers-wrapper.access-display-toast .access-methods-list .subscription-service .offer-details,
    #access-offers-in-page-container .access-offers-in-page.access-display-toast .access-methods-list .subscription-service .offer-details {
      text-align: inherit;
      margin-bottom:10px;
    }
    
    .access-offers-wrapper.access-display-toast .access-methods-list .subscription-service:has([class^="featured-"]),
    .access-offers-wrapper.access-display-toast .access-methods-list .subscription-service:has([class^="featured-"]):hover,
    .access-offers-wrapper.access-display-toast .access-methods-list .subscription-service {
        transform: unset;
        transition: unset;
    }

    .access-offers-wrapper.access-display-toast .access-methods-list .subscription-service:has([class^="featured-"]) {
        order: -1;
    }

    .access-offers-wrapper.access-display-toast .access-methods-list .subscription-service:has([class^="featured-"]):hover {
        box-shadow: unset;
    }
}

/* Paywall Changes */
#access-offers-modal.access-display-toast .modal-header h3 {
    color:#fff;
}

  #access-offers-modal.access-display-toast #access-modal-description {
    display: none;
  }

  #access-offers-modal.access-offers-modal.access-display-toast .modal-content {
    background:#ffffff;
    border:unset;
    border-radius:unset;
}
#access-offers-modal.access-offers-wrapper.access-display-toast .user-login-or-container .user-login-or {
    background: #ffffff; 
}

#access-offers-modal.access-offers-modal.access-display-toast .modal-content .modal-body {
    padding-top: 30px;
}

#access-offers-modal.access-display-toast #access-methods,
#access-offers-modal.access-display-toast #access-methods .promotion-service.panel
.access-offers-wrapper.access-display-toast .access-methods-list {
    margin: 0;
    padding: 0;
    grid-row-gap: 30px;
}

#access-offers-modal.access-display-toast #access-methods .promotion-service.panel .method-header {
    padding: 30px 0 0;
}

#access-offers-modal.access-offers-modal.access-display-toast .modal .modal-dialog {
    margin: auto;
    max-width: 100% !important;
    transition: all 0.5s ease-in-out;
}

#access-offers-modal.access-offers-modal.access-display-toast .modal-header {
    display:grid;
    background: #000000;
    grid-template-columns: auto 2rem;
}
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-promo .modal-header {
    display:block;
}

/* In Page Paywall Changes */
#access-offers-in-page-container .subscriber-offers.access-offers-in-page.access-offers-wrapper.access-display-toast header .offer-group-title {
      background: #000000;
      color: #ffffff;
      padding: 15px;
}

#access-offers-in-page-container .subscriber-offers.access-offers-in-page.access-offers-wrapper.access-display-toast .access-methods-list{
    padding: 0 20px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

/* Purchase Tab header adjustments -- Start */
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-signup .modal-header,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-login .modal-header,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-forgot .modal-header,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-instant-login .modal-header,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-purchase .modal-header {
    display:grid;
    background: #000000;
    grid-template-columns: auto 1fr auto;
}

#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-signup .modal-header .tab-back,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-login .modal-header .tab-back,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-forgot .modal-header .tab-back,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-instant-login .modal-header .tab-back,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-purchase .modal-header .tab-back {
    grid-column: 1;
    grid-row:1;
}

#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-signup .modal-header .close,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-login .modal-header .close,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-forgot .modal-header .close,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-instant-login .modal-header .close,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-purchase .modal-header .close {
    grid-column: 3;
    grid-row:1;
}

#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-signup .modal-header h3,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-login .modal-header h3,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-forgot .modal-header h3,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-instant-login .modal-header h3,
#access-offers-modal.access-offers-modal.access-display-toast #user-modal-tab-purchase .modal-header h3 {
    grid-column: 2;
    grid-row:1;
}

  /* Purchase Tab header adjustments -- End */

#access-offers-modal.access-offers-modal.access-display-toast .modal-header .close,
#access-offers-in-page-container .access-offers-in-page.access-display-toast .close {
    opacity: .5;
    color: #999;
    font-weight: 500;
    grid-row: span 2;
}
#access-offers-in-page-container .access-offers-in-page.access-display-toast .close {
    position: relative;
}
#access-offers-in-page-container .access-offers-in-page.access-display-toast .close span {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 1.5em;
    width: 1.5em;
    line-height: 1.5em;
}
#access-offers-modal.access-offers-modal.access-display-toast .modal-header .close:hover
#access-offers-in-page-container .access-offers-in-page.access-display-toast .close:hover {
    opacity: 1;
}

#access-offers-modal.access-offers-modal.access-display-toast .modal-header .offer-group-title:before {
    display:block;
    content: '';
    height: 3rem;
    margin: 0 0 1rem 0;
}

#access-offers-modal.access-offers-modal.access-display-toast .modal .modal-offers-tab .modal-header .close {
    color: #666;
    transition: 300ms;
}

#access-offers-modal.access-offers-modal.access-display-toast .modal .modal-offers-tab .modal-header .close:hover,
#access-offers-modal.access-offers-modal.access-display-toast .modal .modal-offers-tab .modal-header .close:focus {
    color: #999;
}

#access-offers-modal.access-offers-modal.access-display-toast .method-footer .canel-anytime {
    color: #999;
}

#access-offers-modal.access-offers-modal.access-display-toast .method-footer .canel-anytime {
    color: #999;
}

#access-offers-modal.access-offers-modal.access-display-toast .method-footer .cancel-anytime a {
    color:#003471;
}

#access-offers-modal.access-offers-modal.access-display-toast .method-footer .cancel-anytime.modal-only {
    display:block;
}

#access-offers-modal.access-offers-modal.access-display-toast legend {
    border-bottom: none;
}
 
#access-offers-modal.access-offers-modal.access-display-toast .form-control {
    border: solid 1px #999999;
}

#access-offers-modal.access-offers-modal.access-display-toast .modal {
    top: auto;
}

#access-offers-modal.access-offers-modal.access-display-toast .modal.modal-scrollable {
    top: 0px;
}

/* Border for User Modal Purchase Toggle -- START*/

#access-offers-modal.access-offers-modal #user-modal-purchase-toggle {
    border-left: 1px solid #000000;
    padding: 0 0 0 4px;
}

/* Border for User Modal Purchase Toggle -- END*/

/* 
 * TOAST THEME
 */

/* iframe container style */ 
.tn-pay-card-wrapper.tn-pay-iframe {
    padding: 0;
    margin: 0 -30px;
}
